<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isUser()">

                <!-- servicio -->
                <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 text-center">
                    <br><h1>Seleccione el Servicio</h1><br>
                    <select class="form-control">
                        <option value="">Economía</option>
                        <option value="">Inapi</option>
                        <option value="">Indap</option>
                        <option value="">Ine</option>
                        <option value="">Sag</option>
                        <option value="">Sercotec</option>
                        <option value="">Sernac</option>
                        <option value="">Sernapesca</option>
                        <option value="">Sernatur</option>
                    </select><br>
                </div>
              </div>
                <hr>
                <!-- tipo -->
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10 text-center">
                    <br><h1>Seleccione Tipo de Encuesta</h1><br>
                </div>
              </div>
              <div class="row justify-content-center align-content-center">
                  <button type="button" class="btn btn-success"  @click="sendDate()">Presencial</button>&nbsp;
                  <button type="button" class="btn btn-success" @click="sendDate()">Telefónica</button>&nbsp;
                  <button type="button" class="btn btn-success" @click="sendDate()">Web</button><br>
              </div>
              <p>&nbsp;</p>
            </div>
            <!-- /.card -->
          </div>

        </div>
        <div v-if="!$gate.isUser()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>
<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                mes: '',
                anio: '',
                form: new Form({
                    mes: '',
                    anio: '',
                })
            }
        },
        methods: {
          loadData(){
            this.$Progress.start();
            if(this.$gate.isAdmin()){
              axios.get("api/user").then(({ data }) => (this.users = data.data));
            }
            this.$Progress.finish();
          },
          sendDate(){
            this.$Progress.start();
            //const mes = this.form.mes;
            //const anio = this.form.anio;
            this.$router.push('/detalle');
            //this.$router.push({path:'/detalle'});
            this.$Progress.finish();
          },
        },
        mounted() {
            console.log('Component mounted.');
        },
        created() {
            this.$Progress.start();
            this.loadData();
            //axios.get("api/profile").then(({ data }) => (this.users = data.data));//(this.form.fill(data.data)));
            this.$Progress.finish();
        }
    }
</script>
