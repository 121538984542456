<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isUser()">

                <div class="card-header">
                    <div class="card-tools">
                        <button type="button" class="btn btn-sm btn-success">
                            <i class="fa fa-file-download"></i>&nbsp;
                            Descargar Excel
                        </button>&nbsp;
                    </div>
                </div>

                <div class="container-fluid">
                    <!-- Page Heading -->
                    <br>
                    <div class="d-sm-flex align-items-center justify-content-center mb-4 text-center">
                        <h1 class="h3 mb-0 text-gray-800 font-weight-bold font-italic">Reporte Avance Campo</h1>
                    </div>

                    <!-- Content Row -->
                    <div class="row">

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card bg-success border-left-primary shadow h-100 py-2">
                                <div class="card-body" >
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-uppercase mb-1">
                                                Total de encuestas</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">1229</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card bg-primary border-left-success shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-uppercase mb-1">
                                                Indice de encuesta completa</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">64%</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card bg-info border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-uppercase mb-1">Tiempo utilizado normalmente
                                            </div>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">8m:32s</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Pending Requests Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card bg-danger border-left-success shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-uppercase mb-1">
                                                Encuestas completas</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">787</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Content Row -->

                    <div class="row">


                        <!-- Pie Chart -->
                        <div class="col-xl-6 col-lg-5">
                            <div class="card shadow mb-4">
                                <!-- Card Header - Dropdown -->
                                <div
                                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 font-weight-bold text-primary">Detalles  </h6>
                                    <select name="cdf" id="cdf" class="form-select">
                                        <option value="" disabled><b>Seleccionar</b>  </option>
                                        <option value="110">Regiones</option>
                                        <option value="120">Edad</option>
                                        <option value="211">Sexo </option>
                                        <option value="212">P1 </option>
                                        <option value="220">P2</option>
                                        <option value="221">P3</option>
                                        <option value="222">P4</option>
                                        <option value="231">P5</option>
                                        <option value="232">P6</option>
                                    </select>

                                </div>
                                <!-- Card Body -->
                                <div class="card-body">
                                   <div>
                                       <img src="https://www.datavoz.cl/img/tabla.jpg" height="100%" width="100%" alt="">
                                   </div>
                                </div>
                            </div>
                        </div>


                        <!-- Area Chart -->
                        <div class="col-xl-6 col-lg-7">
                            <div class="card shadow mb-4">
                                <!-- Card Header - Dropdown -->
                                <div
                                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 font-weight-bold text-primary">Encuestas por Regiones</h6>

                                </div>
                                <!-- Card Body -->
                                <div class="card-body">
                                    <div>
                                        <img src="https://www.datavoz.cl/img/grafico.jpg"  height="100%" width="100%" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <!-- Content Row -->
                    <div class="row">

                        <!-- Content Column -->
                        <div class="col-lg-6 mb-4">

                            <!-- Color System -->
                            <div class="row">
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-primary text-white shadow">
                                        <div class="card-body">
                                            Declaración de operación de Recolectores de Orilla
                                            <div class="text-white-50 small">122</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-primary text-white shadow">
                                        <div class="card-body">
                                            Declaración de operaciones de Desembarque Artesanal
                                            <div class="text-white-50 small">75</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-success text-white shadow">
                                        <div class="card-body">
                                            Pesca recreativa
                                            <div class="text-white-50 small">321</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-success text-white shadow">
                                        <div class="card-body">
                                            Plantas, comercializadoras y exportadoras
                                            <div class="text-white-50 small">122</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-danger text-white shadow">
                                        <div class="card-body">
                                            Solicitar certificado de inscripción en el registro pesquero artesanal
                                            <div class="text-white-50 small">147</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-danger text-white shadow">
                                        <div class="card-body">
                                            otros 1
                                            <div class="text-white-50 small">#</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-dark text-black shadow">
                                        <div class="card-body">
                                            otros 2
                                            <div class="text-black-50 small">#</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <div class="card bg-dark text-white shadow">
                                        <div class="card-body">
                                            otros 2
                                            <div class="text-white-50 small">#</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>
            <!-- /.card -->
          </div>
        </div>
        <div v-if="!$gate.isUser()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>
<script>
    export default {
        data () {
            return {
                editmode: false,
                pagos : {},
                pagosB : {},
                pagosid : {},
                btnValue: '',
                form: new Form({
                    id : '',
                    estado: '',
                    comentario: '',
                    mes: this.$route.query.mes,
                    anio: this.$route.query.anio,
                })
            }
        },
        methods: {
            laodPagos(){
                this.$Progress.start();
                axios.get("api/encuesta/selectPagos",{params: {mes: this.$route.query.mes, anio: this.$route.query.anio}})
                .then(({ data }) =>(this.pagos = data.data));
                this.$Progress.finish();
            },
        },
        mounted() {
            console.log('Component mounted.');
        },
        created() {
        }
    }
</script>
